/**
 * CardSwiper
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CardSwiper
 * @param {object} props - Props
 */

import React from "react";
import SwiperCore, { Pagination, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from '../../atoms/link';
import VideoButtonOverlay from "../video-button-overlay";

SwiperCore.use([Pagination, A11y, EffectFade]);
const CardSwiper = ({ items, fade = false }) => {
    return (
        <>
            {Array.isArray(items) && (
                <div className="swiper-parent-container">
                    <Swiper
                        slidesPerView={1}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}

                        centeredSlides={true}
                        spaceBetween={15}
                        pagination={{ "clickable": true }}
                        effect={fade ? 'fade' : 'slide'}
                    >
                        {items.map(({ video, image, link }, idx) => {
                            return (
                                <SwiperSlide key={idx}>

                                    {video && <VideoButtonOverlay videoUrl={link} />}

                                    <Link
                                        to={link}
                                        className={`swiper-slide-image ${video ? 'swiper-slide-image--with-video' : ''}`}
                                        style={{ backgroundImage: `url(${image})` }}
                                    >&nbsp;</Link>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            )}
        </>
    )
};

export default CardSwiper;

/**
 * CardArticle
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CardArticle
 * @param {object} props - Props
 */

import React from "react";
import {Link} from "../../atoms/link";
import Button from "../../atoms/button";
import CardSwiper from "../card-swiper";
import VideoButtonOverlay from "../video-button-overlay";
import Parser from 'html-react-parser';

const CardArticle = (
    {
        // content
        title,
        preTitle: categories = [],
        date,
        description,
        link,
        image,

        dynamic, // if true items (field) are required
        items, // optional

        buttonLabel = null,
        buttonSize,
        buttonColor,
        buttonLink,
        buttonClass,

        // style
        video = false,
        align = 'center', // enum: left, right, center
        thumbnail = false,
        layout = 'vertical',
        noShadow = false,
        ...props
    }) => {

    if (description?.length > 120){
        description = description.slice(0, 117) + '...';
    }

    return (
        <div
            className={
                `card-article 
                ${align === 'left' ? 'card-article--align-left' : align === 'right' ? 'card-article--align-right' : ''} 
                ${thumbnail ? 'card-article--thumbnail' : ''} 
                ${noShadow ? 'card-article--no-shadow' : ''}
                ${layout === 'horizontal' ? 'card-article--horizontal' : ''}
                ${dynamic ? 'card-article--dynamic' : ''} 
                `}>

            { video && <VideoButtonOverlay videoUrl={link} /> }
            { (image && !dynamic) && <Link to={link} className={`card-article__image`} style={{backgroundImage: `url(${image})`}}>&nbsp;</Link> }
            { (dynamic && Array.isArray(items) && items.length === 1) && (<Link to={items[0].link} className="card-article__image" style={{backgroundImage: `url(${items[0].image})`}}>&nbsp;</Link>) }
            { (dynamic && Array.isArray(items) && items.length > 1) && (<CardSwiper items={items}/>) }

            <div className="card-article__body">
                {categories?.length > 0 ? <p className="card-article__categories">
                    {categories?.length > 0 && categories.map((category, idx) => (
                        <Link key={idx} to={"#"}>{category}</Link>
                    )).reduce((prev, curr) => [prev, ', ', curr])}
                </p> : null}
                {date && <p className="card-article__date">{date}</p>}
                {title && <Link to={link} className="card-article__title">{title}</Link>}
                {description && <div className="card-article__description">{Parser(description)}</div>}
                {buttonLabel && (
                    <Button
                        className={`primary card-article__button ${buttonClass}`}
                        color={buttonColor || 'blue'}
                        to={buttonLink}
                        label={buttonLabel}
                    />
                )}
            </div>
        </div>
    );
};

export default CardArticle;

/**
 * VideoButtonOverlay
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class VideoButtonOverlay
 * @param {object} props - Props
 */

import React from "react";
// import Button from "../../atoms/button";
import Icon from "../../atoms/icon";
import {Link} from '../../atoms/link';

const getElement = (id) => document.getElementById(id);

const VideoButtonOverlay = ({videoUrl}) => {

    const getId = url => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    const openVideo = event => {
        // const videoPlayer = document.getElementById("videoPlayer");
        const videoPlayerClose = document.getElementById("videoPlayer--close");
        const videoWrapper = document.getElementById("videoPlayer--container");

        const embed = videoWrapper.getElementsByTagName('iframe');
        const videoId = getId(videoUrl);

        embed[0].src = '//www.youtube.com/embed/'+videoId+'?autoplay=1';

        const mainWrapper = getElement('main')
        
        mainWrapper.classList.add('video-player-visible');
        videoPlayerClose.addEventListener("click", (e) => {
            embed[0].src = '';
            e.preventDefault();
            e.stopPropagation();
            mainWrapper.classList.remove('video-player-visible');
        });

    }

    return (
        <div className="video-button-overlay-container">
            <Link
                onClick={event => openVideo(event)}
            >
                <Icon
                    className="storybook"
                    color="multicolor"
                    name="system-play"
                />
            </Link>
        </div>
    );
}

export default VideoButtonOverlay;
